import React from 'react'
import { RichContent } from '@corratech/pylot-rich-content'
import { useCmsBlocks } from '@pylot-data/hooks/cms/use-cms-blocks'
import cn from 'classnames'
export const BLOG_POST_LIMIT = 4

type Props = {
    identifier: string
    className?: string
}
export const BlogCmsBlockContent: React.FC<Props> = ({
    identifier,
    className
}): JSX.Element | null => {
    const { items } = useCmsBlocks([identifier], {
        revalidateOnMount: true,
        revalidateOnFocus: false
    })

    if (!items) return null

    const blockContent = items?.[0]?.content
    return (
        <div className={cn('cms-content blog-cms', className)}>
            <div>
                <RichContent html={blockContent} />
            </div>
        </div>
    )
}
